<template>
  <them-moi-thu-tuc-hanh-chinh />
</template>
<script>
import ThemMoiThuTucHanhChinh from '@/modules/danh-muc/components/pages/danh-muc/thu-tuc-hanh-chinh/ThemMoiThuTucHanhChinh.vue'

export default {
  components: {
    ThemMoiThuTucHanhChinh,
  },
}
</script>
