<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Tên vai trò"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="nhomNguoiDungId"
        :rules="{ required: true }"
        name="nhomNguoiDungId"
      >
        <treeselect
          ref="nhomNguoiDungId"
          v-model="dataForm.nhomNguoiDungId"
          v-format-v-select
          :options="dataForm.npCbx"
          placeholder="Chọn tên vai trò"
          :normalizer="normalizer"
          multiple
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          :match-keys="['label', 'label2']"
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import { removeDiacritical } from '@/utils/index'

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormGroup,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tthcCbx: [],
      npCbx: [],
      nndCbx: [],
    }
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.tenDonVi),
      }
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep .vs__selected {
          background-color:#005BD4 !important;
}
::v-deep .vs__deselect:disabled svg.feather.feather-x{
  display: none !important;
}
</style>
