<template>
  <div>
    <b-form-group
      v-if="!data.disableCreate || !data.disableEdit"
      id="input-group-1"
      label="Thủ tục"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="thuTuc"
        :rules="{ required: true }"
        name="Thủ tục"
      >
        <v-select
          v-model="data.thuTucHanhChinhId"
          placeholder="Chọn thủ tục hành chính"
          :options="tthcCbx"
          :reduce="se => se.id"
          label="label"
          :filter="onFilterSelect"
          class="w-100 style-chooser"
          :disabled="true"
        >
          <template #no-options>
            Không có dữ liệu
          </template>
        </v-select>
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Chi tiết cấu hình quy trình"
      class="required"
    >

      <vue-good-table
        :columns="getColumns"
        :rows="data.ds"
      >
        <div
          slot="emptystate"
          class="text-center"
        >
          Không có dữ liệu
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field == 'donViId'">
            <validation-provider
              v-slot="{ errors }"
              :key="props.index"
              :rules="{ required: true }"
              :name="`Đơn vị ${props.index}`"
            >
              <treeselect
                v-model="data.ds[props.index].donViId"
                v-format-v-select
                :options="dvCbx"
                placeholder="Chọn đơn vị"
                :normalizer="normalizer"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                :match-keys="['label', 'name']"
                @input="changeDonVi(props.index)"
              />
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </span>
          <span v-else-if="props.column.field == 'mauHoSoId'">
            <validation-provider
              v-slot="{ errors }"
              :key="props.index"
              :rules="{ required: true }"
              :name="`Mã hồ sơ ${props.index}`"
            >
              <treeselect
                v-model="data.ds[props.index].mauHoSoId"
                v-format-v-select
                :options="getMauHoSo(data.ds[props.index].mhsCbx, data.ds[props.index].mauHoSoId)"
                placeholder="Chọn mã hồ sơ"
                :normalizer="normalizer"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                :match-keys="['label', 'name']"
                :disabled="data.thuTucHanhChinhId ? false : true"
              />
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </span>
          <span v-else-if="props.column.field == 'workflowSchemeAttributeId'">
            <validation-provider
              v-slot="{ errors }"
              :key="props.index"
              :rules="{ required: true }"
              :name="`Quy trình ${props.index}`"
            >
              <v-select
                v-model="data.ds[props.index].workflowSchemeAttributeId"
                v-format-v-select
                :options="getDataQuyTrinhTheoDonVi(data.ds[props.index].donViId)"
                :reduce="se => se.id"
                label="description"
                placeholder="Chọn quy trình"
                :filter="onFilterSelect"
                class="w-100 style-chooser"
                :disabled="data.ds[props.index].donViId ? false : true"
              >
                <template #no-options>
                  Không có dữ liệu
                </template>
              </v-select>
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </span>
          <span v-else-if="props.column.field == 'tongSoNgayXuLy'">
            <validation-provider
              v-slot="{ errors }"
              :key="props.index"
              :rules="{ required: true }"
              :name="`tong So Ngay Xu Ly ${props.index}`"
            >
              <b-form-input
                id="input-1"
                v-model="data.ds[props.index].tongSoNgayXuLy"
                v-block-e
                v-format-positive-integers
                v-is-int
                type="number"
                placeholder="Nhập số ngày xử lý"
                required
              />
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <div class="d-flex justify-content-center">
              <plus-square-icon
                v-if="props.index + 1 == dataForm.ds.length"
                v-b-tooltip.hover.v-secondary
                title="Thêm"
                size="16"
                class="custom-class cursor-pointer mr-1"
                @click="e => dataForm.ds.push({workflowSchemeAttributeId: null, mauHoSoId: null, donViId: null, mhsCbx: dataForm.mhsCbx})"
              />
              <trash-2-icon
                v-if="dataForm.ds.length > 1"
                v-b-tooltip.hover.v-secondary
                title="Xoá"
                size="16"
                class="custom-class cursor-pointer"
                @click="deleteCauHinhQuyTrinh(props.index)"
              />
            </div>
          </span>
          <span v-else-if="props.column.field == 'STT'">
            {{ rowNumber(props) }}
          </span>
        </template>
      </vue-good-table>
    </b-form-group>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BFormGroup,
  VBTooltip,
  BFormInput,
} from 'bootstrap-vue'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import _cloneDeep from 'lodash/cloneDeep'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import { removeDiacritical } from '@/utils/index'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { PlusSquareIcon, Trash2Icon } from 'vue-feather-icons'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormGroup,
    VueGoodTable,
    BFormInput,
    PlusSquareIcon,
    Trash2Icon,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errorTrungMa: null,
      dvCbx: [],
      tthcCbx: [],
      lxlCbx: [],
      data: {
        ds: [
          {
            workflowSchemeAttributeId: null,
            mauHoSoId: null,
            donViId: null,
            mhsCbx: [],
          },
        ],
        thuTucHanhChinhId: null,
        disableCreate: true,
        disableEdit: true,
      },
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center vertical-align-middle',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Đơn vị',
          field: 'donViId',
          thClass: 'text-center',
          width: '350px',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Quy trình',
          field: 'workflowSchemeAttributeId',
          thClass: 'text-center',
          width: '350px',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Số ngày xử lý',
          field: 'tongSoNgayXuLy',
          thClass: 'text-center',
          width: '150px',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Mẫu hồ sơ',
          field: 'mauHoSoId',
          thClass: 'text-center',
          width: '350px',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          thClass: 'text-center',
          width: '100px',
          tdClass: 'vertical-align-middle',
        },
      ],
      rows: [],
    }
  },
  computed: {
    getColumns() {
      return this.dataForm?.id ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  async created() {
    const param = {
      page: 1,
      size: 9999999,
      code: null,
      status: true,
      donviId: null,
      fields: null,
      fullTextSearch: null,
      id: null,
      listId: null,
      sort: '+Id',
    }
    await this.getDataDonVi()
    await this.getDataThuTuc()
    await this.getDataQuyTrinh(param)
    this.data = this.dataForm
  },
  methods: {
    clearTatCaCacTruong(index) {
      this.data.ds[index].mauHoSoId = null
      this.data.ds[index].workflowSchemeAttributeId = null
    },
    rowNumber(row) {
      return row.index + 1
    },
    changeDonVi(index) {
      if (!this.data.ds[index].donViId) {
        this.data.ds[index].workflowSchemeAttributeId = null
      }
      this.dataForm.ds[index].donViId = this.data.ds[index].donViId
    },
    async getDataDonVi() {
      await this.$axios.get(END_POINTS.CHUNG.DON_VI).then(res => {
        if (res.data?.succeeded) {
          const formatDonVi = _cloneDeep(res.data.data)
          this.dvCbx = this.listToTree(formatDonVi)
        }
      })
    },
    getMauHoSo(mhsCbx, mauHoSoId) {
      const result = this.data.ds.map(item => item.mauHoSoId).filter(item => item !== mauHoSoId)
      if (result && result.length > 0) {
        return mhsCbx.filter(item => !result.includes(item.id))
      }
      return mhsCbx
    },
    async getDataThuTuc() {
      await this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.tthcCbx = res.data.data.map(item => ({
            ...item,
            label: `${item.code} - ${item.name}`,
          }))
        }
      })
    },
    async getDataQuyTrinh(payload = null) {
      await this.$axios.post(END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.DS, payload, false).then(res => {
        if (res.data?.code === 200) {
          this.lxlCbx = res.data.data.content
        }
      })
    },
    getDataQuyTrinhTheoDonVi(donViId) {
      return this.lxlCbx.filter(item => item.donViId === donViId)
    },
    listToTree(array, parent, tree) {
      // eslint-disable-next-line no-param-reassign
      tree = typeof tree !== 'undefined' ? tree : []
      // eslint-disable-next-line no-param-reassign
      parent = typeof parent !== 'undefined' ? parent : { donViId: '00000000-0000-0000-0000-000000000000' }
      const children = _filter(array, child => child.iD_DonVi_Cha === parent.donViId)
      if (!_isEmpty(children)) {
        if (parent.donViId === '00000000-0000-0000-0000-000000000000') {
          // eslint-disable-next-line no-param-reassign
          tree = children
        } else {
          // eslint-disable-next-line no-param-reassign
          parent.children = children
        }
        children.forEach(child => {
          this.listToTree(array, child)
        })
      }
      return tree
    },
    normalizer(node) {
      if (node.children) {
        return {
          id: node.donViId,
          label: node.tenDonVi,
          name: removeDiacritical(node.tenDonVi),
          children: node.children,
        }
      }
      return {
        id: node.donViId || node.id,
        label: node.tenDonVi || node.tenMauHoSo,
        name: removeDiacritical(node.tenDonVi) || removeDiacritical(node.tenMauHoSo),
      }
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    deleteCauHinhQuyTrinh(idx) {
      this.dataForm.ds = this.dataForm.ds.filter((item, index) => index !== idx)
    },
  },
}
</script>
