<template>
  <div>
    <good-table
      ref="vgTable"
      class="mt-1 fixed-column"
      :columns="dataForm.columns"
      :rows="dataForm.rows"
      :pagination="false"
      @selected-item="selectedItem"
    />
  </div>
</template>
<script>
import GoodTable from '@/components/GoodTable.vue'

export default {
  components: {
    GoodTable,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    selectedItem(data) {
      this.$emit('selected-item', data)
    },
  },
}
</script>
