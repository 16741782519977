var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Mã quy trình","label-for":"input-1"}},[_c('validation-provider',{key:"maWorkflow",attrs:{"rules":{ required: true },"name":"maWorkflow"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"trim-input",rawName:"v-trim-input"}],ref:"maWorkflow",attrs:{"id":"input-1","type":"text","autofocus":_vm.data.id ? false : true,"placeholder":"Nhập mã workflow","required":""},on:{"input":function (e) { return _vm.dataForm.code = _vm.data.code; }},model:{value:(_vm.data.code),callback:function ($$v) {_vm.$set(_vm.data, "code", $$v)},expression:"data.code"}}),_c('div',{staticClass:"label-noti-validate"},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('div',{staticClass:"label-noti-validate"},[_vm._v(" "+_vm._s(_vm.errorTrungMa)+" ")])]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-1","label":"Tên quy trình","label-for":"input-1"}},[_c('b-form-input',{directives:[{name:"trim-input",rawName:"v-trim-input"}],attrs:{"id":"input-1","type":"text","placeholder":"Nhập vào tên quy trình","required":""},on:{"input":function (e) { return _vm.dataForm.description = _vm.data.description; }},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}})],1),_c('b-form-group',{attrs:{"id":"input-group-1","label":"Thứ tự","label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":"input-1","type":"number","placeholder":"Nhập số thứ tự","required":""},on:{"change":_vm.notAllowMin,"input":function (e) { return _vm.dataForm.order = _vm.data.order; }},model:{value:(_vm.data.order),callback:function ($$v) {_vm.$set(_vm.data, "order", _vm._n($$v))},expression:"data.order"}})],1),_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Mẫu hồ sơ","label-for":"input-1"}},[_c('validation-provider',{key:"mauHoSo",attrs:{"rules":{ required: true },"name":"mauHoSo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],ref:"mauHoSo",attrs:{"id":"linhVuc","options":_vm.data.dsmauHoSo,"placeholder":"Chọn mẫu hồ sơ","no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","match-keys":['label', 'name']},on:{"change":function (e) { return _vm.dataForm.donViId = _vm.data.donViId; }},model:{value:(_vm.data.mauHoSoId),callback:function ($$v) {_vm.$set(_vm.data, "mauHoSoId", $$v)},expression:"data.mauHoSoId"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Số ngày xử lý","label-for":"input-1"}},[_c('validation-provider',{key:"soNgayXuLy",attrs:{"rules":{ required: true },"name":"soNgayXuLy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"block-e",rawName:"v-block-e"},{name:"format-positive-integers",rawName:"v-format-positive-integers"},{name:"is-int",rawName:"v-is-int"}],ref:"soNgayXuLy",attrs:{"id":"input-1","type":"number","placeholder":"Nhập số ngày xử lý","required":""},on:{"change":_vm.notAllowMin,"input":function (e) { return _vm.dataForm.soNgayXuLy = _vm.data.soNgayXuLy; }},model:{value:(_vm.data.soNgayXuLy),callback:function ($$v) {_vm.$set(_vm.data, "soNgayXuLy", _vm._n($$v))},expression:"data.soNgayXuLy"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Đơn vị","label-for":"input-1"}},[_c('validation-provider',{key:"donVi",attrs:{"rules":{ required: true },"name":"donVi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],ref:"donVi",attrs:{"id":"linhVuc","options":_vm.dvCbx,"placeholder":"Chọn đơn vị","normalizer":_vm.normalizer,"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","match-keys":['label', 'name']},on:{"change":function (e) { return _vm.dataForm.donViId = _vm.data.donViId; }},model:{value:(_vm.data.donViId),callback:function ($$v) {_vm.$set(_vm.data, "donViId", $$v)},expression:"data.donViId"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-1","label":"Trạng thái","label-for":"input-1"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","switch":""},model:{value:(_vm.dataForm.status),callback:function ($$v) {_vm.$set(_vm.dataForm, "status", $$v)},expression:"dataForm.status"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }