<template>
  <b-card>
    <div class="mb-1">
      <div class="mb-1 padding-4px border-bottom d-flex justify-content-between">
        <span>
          <span class="css-step">Bước 1</span><span class="ml-1 font-weight-700">Thông tin thủ tục hành chính</span><span class="text-red-cor"> *</span>
        </span>
        <span class="d-none">
          <input
            id="radio1"
            v-model="step1"
            type="radio"
            name="step_1"
            class="d-none"
            :value="true"
          >
          <input
            id="radio2"
            v-model="step1"
            type="radio"
            name="step_1"
            class="d-none"
            :value="false"
          >
          <label
            v-show="!step1"
            for="radio1"
            class="cursor-pointer"
          >
            <chevrons-down-icon size="14" /> Mở rộng
          </label>
          <label
            v-show="step1"
            for="radio2"
            class="cursor-pointer"
          >
            <chevrons-up-icon size="14" /> Thu gọn
          </label>
        </span>
      </div>
      <div class="mb-1">
        <good-table
          v-if="rowsThuTucHanhChinh && rowsThuTucHanhChinh.length > 0"
          ref="vgTable"
          class="mt-1 fixed-column"
          :columns="columnsThuTucHanhChinh"
          :rows="rowsThuTucHanhChinh"
          :pagination="false"
          :selectable="false"
        >
          <div
            slot="actions"
            slot-scope="{ props }"
          >
            <edit-icon
              v-b-tooltip.hover.v-secondary
              title="Sửa"
              size="16"
              class="custom-class cursor-pointer mr-1"
              @click="updateCommom(props.row)"
            />
          </div>
        </good-table>
        <div
          v-else
          class="padding-10px border-grey-cor background-grey-cor text-center text-primary-cor"
        >
          Vui lòng nhấn Thêm mới để khởi tạo thủ tục hành chính
        </div>
      </div>
      <div
        v-if="!(rowsThuTucHanhChinh.length > 0)"
        class="d-sm-flex justify-content-end"
      >
        <button
          class="compact ui button btn-primary"
          @click="addThuTucHanhChinh()"
        >
          Thêm mới
        </button>
      </div>
    </div>
    <div class="mb-1">
      <div
        class="mb-1 border-bottom d-flex justify-content-between cursor-pointer"
        @click="setValueStep(!step1, false, false, false)"
      >
        <span>
          <span class="css-step">Bước 2</span><span class="ml-1 font-weight-700">Thông tin thành phần hồ sơ</span><span class="text-red-cor"> *</span>
        </span>
        <span>
          <label
            v-show="!step1"
            class="cursor-pointer"
          >
            <chevrons-down-icon size="14" /> Mở rộng
          </label>
          <label
            v-show="step1"
            class="cursor-pointer"
          >
            <chevrons-up-icon size="14" /> Thu gọn
          </label>
        </span>
      </div>
      <div v-show="step1">
        <GiayToMauHoSoFrom
          :data-form="thanhPhanHoSo"
          @update-data="editorPaper"
        />
      </div>
    </div>
    <div class="mb-1">
      <div
        class="mb-1 border-bottom d-flex justify-content-between cursor-pointer"
        @click="setValueStep(false, !step2, false, false)"
      >
        <span>
          <span class="css-step">Bước 3</span><span class="ml-1 font-weight-700">Thông tin biểu mẫu - tờ khai</span><span class="text-red-cor"> *</span>
        </span>
        <span>
          <label
            v-show="!step2"
            class="cursor-pointer"
          >
            <chevrons-down-icon size="14" /> Mở rộng
          </label>
          <label
            v-show="step2"
            class="cursor-pointer"
          >
            <chevrons-up-icon size="14" /> Thu gọn
          </label>
        </span>
      </div>
      <div v-show="step2">
        <div class="mb-1">
          <good-table
            v-if="rowsBieuMau && rowsBieuMau.length"
            ref="vgTable"
            class="mt-1 fixed-column"
            :columns="columnsBieuMau"
            :rows="rowsBieuMau"
            :pagination="false"
            :selectable="false"
          >
            <div
              slot="actions"
              slot-scope="{ props }"
            >
              <edit-icon
                v-b-tooltip.hover.v-secondary
                title="Sửa biểu mẫu"
                size="16"
                class="custom-class cursor-pointer mr-1"
                @click="updateBieuMau(props.row)"
              />
              <copy-icon
                v-b-tooltip.hover.v-secondary
                title="Sao chép bản ghi"
                size="16"
                class="mr-1 cursor-pointer custom-class"
                @click="copyBieuMau(props.row)"
              />
              <trash-2-icon
                v-b-tooltip.hover.v-secondary
                title="Xóa"
                size="16"
                class="custom-class cursor-pointer"
                @click="deleteBieuMau(props.row)"
              />
            </div>
          </good-table>
          <div
            v-else
            class="padding-10px border-grey-cor background-grey-cor text-center text-primary-cor"
          >
            Vui lòng nhấn Thêm mới để khởi tạo cấu hình biểu mẫu - tờ khai với TTHC
          </div>
        </div>
        <div class="d-sm-flex justify-content-end">
          <button
            class="compact ui button btn-primary"
            @click="addNewBieuMau()"
          >
            Thêm mới
          </button>
          <button
            class="compact ui button btn-primary"
            @click="addBieuMau()"
          >
            Chọn từ danh mục
          </button>
        </div>
      </div>
    </div>
    <div class="mb-1">
      <div
        class="mb-1 border-bottom d-flex justify-content-between cursor-pointer"
        @click="setValueStep(false, false, !step3, false)"
      >
        <span>
          <span class="css-step">Bước 4</span><span class="ml-1 font-weight-700">Phân quyền TTHC - vai trò</span><span class="text-red-cor"> *</span>
        </span>
        <span>
          <label
            v-show="!step3"
            class="cursor-pointer"
          >
            <chevrons-down-icon size="14" /> Mở rộng
          </label>
          <label
            v-show="step3"
            class="cursor-pointer"
          >
            <chevrons-up-icon size="14" /> Thu gọn
          </label>
        </span>
      </div>
      <div v-show="step3">
        <good-table
          v-if="rowsPhanQuyen && rowsPhanQuyen.length > 0"
          ref="vgTable"
          class="mt-1 fixed-column mb-1"
          :columns="columnsPhanQuyen"
          :rows="rowsPhanQuyen"
          :pagination="false"
          :selectable="false"
        >
          <div
            slot="actions"
            slot-scope="{ props }"
          >
            <trash-2-icon
              v-b-tooltip.hover.v-secondary
              title="Xóa"
              size="16"
              class="custom-class cursor-pointer"
              @click="deletePhanQuyen(props.row)"
            />
          </div>
        </good-table>
        <div
          v-else
          class="mb-1"
        >
          <div class="padding-10px border-grey-cor background-grey-cor text-center text-primary-cor">
            Vui lòng nhấn Thêm mới để khởi tạo phân quyền TTHC - vai trò
          </div>
        </div>
        <div class="d-sm-flex justify-content-end">
          <button
            class="compact ui button btn-primary"
            @click="addPhanQuyen()"
          >
            Thêm mới
          </button>
        </div>
      </div>
    </div>
    <div class="mb-1">
      <div
        class="mb-1 border-bottom d-flex justify-content-between cursor-pointer"
        @click="setValueStep(false, false, false, !step4)"
      >
        <span>
          <span class="css-step">Bước 5</span><span class="ml-1 font-weight-700">Cấu hình quy trình</span><span class="text-red-cor"> *</span>
        </span>
        <span>
          <label
            v-show="!step4"
            class="cursor-pointer"
          >
            <chevrons-down-icon size="14" /> Mở rộng
          </label>
          <label
            v-show="step4"
            class="cursor-pointer"
          >
            <chevrons-up-icon size="14" /> Thu gọn
          </label>
        </span>
      </div>
      <div v-show="step4">
        <div
          v-if="rowsQuyTrinh && rowsQuyTrinh.length > 0"
          class="mb-1"
        >
          <good-table
            ref="vgTable"
            class="mt-1 fixed-column"
            :columns="columnsQuyTrinh"
            :rows="rowsQuyTrinh"
            :pagination="false"
            :selectable="false"
          >
            <div
              slot="actions"
              slot-scope="{ props }"
            >
              <edit-icon
                v-b-tooltip.hover.v-secondary
                title="Sửa"
                size="16"
                class="custom-class cursor-pointer mr-1"
                @click="updateQuyTrinh()"
              />
              <trash-2-icon
                v-b-tooltip.hover.v-secondary
                title="Xóa"
                size="16"
                class="custom-class cursor-pointer"
                @click="deleteQuyTrinh(props.row)"
              />
            </div>
          </good-table>
        </div>
        <div
          v-else
          class="mb-1"
        >
          <div class="padding-10px border-grey-cor background-grey-cor text-center text-primary-cor">
            Vui lòng nhấn Thêm mới để khởi tạo cấu hình quy trình
          </div>
        </div>
        <div class="d-sm-flex justify-content-end">
          <button
            class="compact ui button btn-primary"
            @click="addNewCauHinhQuyTrinh()"
          >
            Thêm mới
          </button>
          <button
            class="compact ui button btn-primary"
            @click="addCauHinhQuyTrinh()"
          >
            Chọn từ danh mục
          </button>
        </div>
      </div>
    </div>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
      @handle-focus="handleFocusError"
    >
      <div
        v-if="!['XoaForm', 'XoaQuyTrinhForm', 'XoaVaiTroForm', 'XoaBieuMauForm'].includes(componentName)"
        :class="componentName === 'DanhMucDanhSach' ? 'scroll-class' : ''"
      >
        <component
          :is="componentName"
          ref="componentName"
          :data-form="dataForm"
          @update-data="editorPaper"
          @selected-item="selectedItemBieuMau"
          @data-compare="setBeginObject"
        />
      </div>
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.hideModal(),$refs.commonnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-card>
</template>
<script>
import {
  BCard,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import END_POINTS from '@/api/endpoints'
import GoodTable from '@/components/GoodTable.vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import AppTable from '@/components/AppTable.vue'
import ThuTucHanhChinhFrom from '@/modules/danh-muc/components/form/ThuTucHanhChinhForm.vue'
import GiayToMauHoSoFrom from '@/modules/danh-muc/components/form/GiayToMauHoSoForm.vue'
import CauHinhQuyTrinhThuTucHanhChinh from '@/modules/quan-ly-quy-trinh/components/form/CauHinhQuyTrinhThuTucHanhChinh.vue'
import KieuHoSoFrom from '@/modules/danh-muc/components/form/KieuHoSoFrom.vue'
import BieuMauFrom from '@/modules/danh-muc/components/form/BieuMauFrom.vue'
import GiayToFrom from '@/modules/danh-muc/components/form/GiayToForm.vue'
import ThuTucHanhChinhVaiTroForm from '@/modules/danh-muc/components/form/ThuTucHanhChinhVaiTroForm.vue'
import LuongXuLyForm from '@/modules/danh-muc/components/form/LuongXuLyForm.vue'
import DanhMucDanhSach from '@/modules/danh-muc/components/form/DanhMucDanhSach.vue'
import BieuMauThuTucHanHChinhForm from '@/modules/danh-muc/components/form/BieuMauThuTucHanhChinhForm.vue'
import {
  EditIcon,
  Trash2Icon,
  CopyIcon,
  Edit3Icon,
  ChevronsDownIcon,
  ChevronsUpIcon,
} from 'vue-feather-icons'
// import _cloneDeep from 'lodash/cloneDeep'
import { checkStatus, compareObjects } from '@/utils/index'
import { STATUS } from '@/modules/danh-muc/constants/constants'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    ActionBtn,
    CopyIcon,
    CommonModal,
    AppTable,
    ThuTucHanhChinhFrom,
    CauHinhQuyTrinhThuTucHanhChinh,
    ThuTucHanhChinhVaiTroForm,
    GiayToMauHoSoFrom,
    LuongXuLyForm,
    DanhMucDanhSach,
    GoodTable,
    BieuMauFrom,
    KieuHoSoFrom,
    GiayToFrom,
    BieuMauThuTucHanHChinhForm,
    EditIcon,
    Trash2Icon,
    Edit3Icon,
    ChevronsDownIcon,
    ChevronsUpIcon,
    BButton,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      tthcId: null,
      lxlCbx: [],
      isShow: false,
      isShowFooter: false,
      rowsThuTucHanhChinh: [],
      thanhPhanHoSo: {
        mhs: [],
      },
      nhomNguoiDung: [],
      title: 'Thêm mới thủ tục hành chính',
      optionsTrangThai: STATUS,
      columnsThuTucHanhChinh: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Mã thủ tục',
          field: 'maTTHC',
          width: '150px',
          thClass: 'text-center',
        },
        {
          label: 'Tên thủ tục',
          field: 'tenTTHC',
          thClass: 'text-center',
          width: '300px',
        },
        {
          label: 'Cơ quan',
          field: 'tenCoQuan',
          thClass: 'text-center',
          width: 'auto',
        },
        {
          label: 'Lĩnh vực',
          field: 'tenLinhVuc',
          thClass: 'text-center',
          width: '300px',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          thClass: 'text-center',
          tdClass: 'text-left',
          width: 'auto',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '100px',
          tdClass: 'text-center vertical-align-middle',
          thClass: 'text-center',
        },
      ],
      rowsBieuMau: [],
      rowsPhanQuyen: [],
      rowsQuyTrinh: [],
      bmCbx: [],
      columnsBieuMau: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên biểu mẫu',
          field: 'name',
          thClass: 'text-center',
          width: 'auto',
        },
        {
          label: 'Trạng thái',
          field: 'stateDisplay',
          thClass: 'text-center',
          tdClass: 'text-left',
          width: '180px',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '100px',
          tdClass: 'text-center vertical-align-middle',
          thClass: 'text-center',
        },
      ],
      columnsPhanQuyen: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên vai trò',
          field: 'tenNhomNguoiDung',
          thClass: 'text-center',
          width: 'auto',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '100px',
          tdClass: 'text-center vertical-align-middle',
          thClass: 'text-center',
        },
      ],
      columnsQuyTrinh: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Đơn vị',
          field: 'tenDonVi',
          thClass: 'text-center',
          width: '300px',
        },
        {
          label: 'Tên mẫu hồ sơ',
          field: 'tenMauHoSo',
          thClass: 'text-center',
          width: 'auto',
        },
        {
          label: 'Quy trình',
          field: 'workflowSchemeCode',
          thClass: 'text-center',
        },
        {
          label: 'Số ngày xử lý',
          field: 'tongSoNgayXuLy',
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '200px',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '100px',
          tdClass: 'text-center vertical-align-middle',
          thClass: 'text-center',
        },
      ],
      selectedItems: [],
      rows: [],
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        maTTHC: null,
        tenTTHC: null,
        listCoQuanId: [],
        listCoQuanText: null,
        listTrangThaiId: [],
        listTrangThaiText: null,
        listBieuMauId: [],
        listBieuMauText: null,
        listLinhVucId: [],
        listLinhVucText: null,
        listKieuHoSoId: [],
        listKieuHoSoText: null,
        pageNumber: 1,
        pageSize: 10,
      },
      dataForm: {
        maTTHC: null,
        soQuyetDinh: null,
        tenTTHC: null,
        linhVucId: null,
        coQuanThucHien: null,
        phi: 0,
        lePhi: 0,
        kieuTTHC: 2,
        loaiDichVuCong: 3,
        loaiDichVuCong2: 1,
        capDoTTHC: 1,
        capDoChiTietTTHC: 1,
        tiepNhanQuaBuuDien: false,
        traKetQuaQuaBuuDien: false,
        trinhTuThucHien: null,
        thanhPhanHoSo: null,
        yeuCauDieuKien: null,
        thoiGianGiaiQuyet: null,
        doiTuongThucHien: null,
        ketQuaGiaiQuyet: null,
        canCuPhapLy: null,
        kieuHoSoId: null,
        status: true,
        cachThucThucHien: null,
        dsMauHoSo: [
          {
            tenMauHoSo: null,
            isDefault: true,
          },
        ],
      },
      isLoading: false,
      size: null,
      isEmty: false,
      lv: [],
      dv: [],
      beginObject: {},
    }
  },
  async created() {
    this.tthcId = this.$route.query.id
    this.getDataQuyTrinh()
    this.getDataBieuMau()
    await this.getDataNhomNguoiDung()
    if (this.$route.query.id) {
      await this.getCauHinhQuyTrinh()
      this.getDataPhanQuyenThuTucHanhChinhNhomNguoiDung()
      await this.getChiTietThuTucHanhChinh(this.tthcId)
      this.getDataBieuMauThuTucHanhChinh()
      Promise.all([this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.DS_MAU_HO_SO_GT, { tthcId: this.tthcId }), this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.CHI_TIET_GIAY_TO, { tthcId: this.tthcId })]).then(ress => {
        this.thanhPhanHoSo.rows = ress[0].data.data
        this.thanhPhanHoSo.mhs = ress[1].data.data ? ress[1].data.data.map(item => ({
          code: item.id,
          id: item.id,
          label: item.tenMauHoSo,
          tenMauHoSo: item.tenMauHoSo,
        })) : []
        this.thanhPhanHoSo.tthcId = this.tthcId
      })
    }
  },
  methods: {
    setBeginObject(value) {
      this.beginObject = value
    },
    openModal() {
      if (this.componentName === 'BieuMauThuTucHanHChinhForm') {
        if (this.title === 'Cập nhật biểu mẫu') {
          if (compareObjects(this.beginObject, this.$refs.componentName.getObjectCompare())) {
            this.$refs.commonModal.hideModal()
            this.$refs.commonnModal.hideModal()
          } else {
            this.$refs.commonnModal.showModal()
            this.isShowFooter = true
          }
        } else if (compareObjects({
          id: '',
          name: '',
          tableName: '',
          canCuPhapLy: '',
          state: true,
          listRow: [],
        }, this.$refs.componentName.getObjectCompare())) {
          this.$refs.commonModal.hideModal()
          this.$refs.commonnModal.hideModal()
        } else {
          this.$refs.commonnModal.showModal()
          this.isShowFooter = true
        }
      } else if (compareObjects(this.beginObject, this.dataForm)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    setValueStep(step1, step2, step3, step4) {
      this.step1 = step1
      this.step2 = step2
      this.step3 = step3
      this.step4 = step4
    },
    getDataQuyTrinh() {
      const payload = {
        page: 1,
        size: 9999999,
        code: null,
        donviId: null,
        fields: null,
        fullTextSearch: null,
        id: null,
        listId: null,
        sort: '+Id',
      }
      this.$axios.post(END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.DS, payload, false).then(res => {
        if (res.data?.code === 200) {
          this.lxlCbx = res.data.data.content
        }
      })
    },
    getDataBieuMau() {
      this.$axios.get(END_POINTS.BIEU_MAU.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.bmCbx = res.data.data
        }
      })
    },
    getDataBieuMauThuTucHanhChinh() {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.LAY_TO_KHAI, { tthcId: this.tthcId, isGetAll: true }).then(res => {
        if (res.data?.succeeded) {
          this.rowsBieuMau = res.data.data
        }
      })
    },
    async addPhanQuyen() {
      this.isShow = true
      this.title = 'Thông tin phân quyền'
      this.componentName = 'ThuTucHanhChinhVaiTroForm'
      this.size = 'lg'
      const arr = this.rowsPhanQuyen.map(item => item.nhomNguoiDungId)
      const result = this.nhomNguoiDung.filter(item => !arr.includes(item.id))
      this.dataForm = {
        npCbx: result,
        nhomNguoiDungId: null,
      }
      this.beginObject = _cloneDeep(this.dataForm)
      this.$refs.commonModal.showModal()
    },
    addThuTucHanhChinh() {
      this.isShow = true
      this.title = 'Thêm mới thủ tục hành chính'
      this.componentName = 'ThuTucHanhChinhFrom'
      this.size = 'xl'
      this.dataForm = {
        maTTHC: null,
        soQuyetDinh: null,
        tenTTHC: null,
        linhVucId: null,
        coQuanThucHien: null,
        phi: 0,
        lePhi: 0,
        kieuTTHC: 2,
        loaiDichVuCong: 3,
        loaiDichVuCong2: 3,
        capDoTTHC: 1,
        capDoChiTietTTHC: 1,
        tiepNhanQuaBuuDien: false,
        traKetQuaQuaBuuDien: false,
        trinhTuThucHien: null,
        thanhPhanHoSo: null,
        yeuCauDieuKien: null,
        thoiGianGiaiQuyet: null,
        doiTuongThucHien: null,
        ketQuaGiaiQuyet: null,
        canCuPhapLy: null,
        kieuHoSoId: null,
        status: true,
        mauDonToKhai: [],
        soBoHoSo: 1,
        cachThucThucHien: null,
        dsMauHoSo: [
          {
            tenMauHoSo: 'Bình thường',
            isDefault: true,
          },
        ],
        coQuanCoThamQuyen: null,
        diaChiTiepNhanHoSo: null,
        coQuanDuocUyQuyen: null,
        coQuanPhoiHop: null,
      }
      this.$refs.commonModal.showModal()
    },
    addNewBieuMau() {
      this.isShow = true
      this.title = 'Tạo mới biểu mẫu'
      this.componentName = 'BieuMauThuTucHanHChinhForm'
      this.size = 'xl'
      this.dataForm = null
      this.$refs.commonModal.showModal()
    },
    updateBieuMau(data) {
      this.isShow = true
      this.title = 'Cập nhật biểu mẫu'
      this.componentName = 'BieuMauThuTucHanHChinhForm'
      this.size = 'xl'
      this.dataForm = data.id
      this.$refs.commonModal.showModal()
    },
    copyBieuMau(data) {
      this.$axios.post(END_POINTS.BIEU_MAU.cloneBieuMau(data.id)).then(res => {
        if (res.data?.succeeded) {
          this.updateCopyBieuMau(res.data.data.id)
        }
      })
    },
    updateCopyBieuMau(id) {
      let arr = []
      if (this.rowsThuTucHanhChinh[0].bieuMauId) {
        arr = [...this.rowsThuTucHanhChinh[0].bieuMauId, ...(this.dataForm ? this.dataForm.bieuMauId : [])]
      } else {
        arr = this.dataForm.bieuMauId
      }
      if (id) {
        arr = [...arr, id]
      }
      const payload = {
        id: this.rowsThuTucHanhChinh[0].id,
        maTTHC: this.rowsThuTucHanhChinh[0].maTTHC,
        soBoHoSo: this.rowsThuTucHanhChinh[0].soBoHoSo,
        tenTTHC: this.rowsThuTucHanhChinh[0].tenTTHC,
        linhVucId: this.rowsThuTucHanhChinh[0].linhVucId,
        coQuanThucHien: this.rowsThuTucHanhChinh[0].coQuanThucHien,
        phi: this.rowsThuTucHanhChinh[0].phi,
        lePhi: this.rowsThuTucHanhChinh[0].lePhi,
        kieuTTHC: this.rowsThuTucHanhChinh[0].kieuTTHC,
        loaiDichVuCong: this.rowsThuTucHanhChinh[0].loaiDichVuCong,
        loaiDichVuCong2: this.rowsThuTucHanhChinh[0].loaiDichVuCong2,
        capDoTTHC: this.rowsThuTucHanhChinh[0].capDoTTHC,
        capDoChiTietTTHC: this.rowsThuTucHanhChinh[0].capDoChiTietTTHC,
        cachThucThucHien: this.rowsThuTucHanhChinh[0].cachThucThucHien,
        tiepNhanQuaBuuDien: this.rowsThuTucHanhChinh[0].tiepNhanQuaBuuDien,
        traKetQuaQuaBuuDien: this.rowsThuTucHanhChinh[0].traKetQuaQuaBuuDien,
        trinhTuThucHien: this.rowsThuTucHanhChinh[0].trinhTuThucHien,
        thanhPhanHoSo: this.rowsThuTucHanhChinh[0].thanhPhanHoSo,
        mauDonToKhai: this.rowsThuTucHanhChinh[0].mauDonToKhais || [],
        yeuCauDieuKien: this.rowsThuTucHanhChinh[0].yeuCauDieuKien,
        thoiGianGiaiQuyet: this.rowsThuTucHanhChinh[0].thoiGianGiaiQuyet,
        doiTuongThucHien: this.rowsThuTucHanhChinh[0].doiTuongThucHien,
        ketQuaGiaiQuyet: this.rowsThuTucHanhChinh[0].ketQuaGiaiQuyet,
        canCuPhapLy: this.rowsThuTucHanhChinh[0].canCuPhapLy,
        trangThaiId: this.rowsThuTucHanhChinh[0].trangThaiId,
        bieuMauId: arr || [],
        kieuHoSoId: this.rowsThuTucHanhChinh[0].kieuHoSoId,
        status: this.rowsThuTucHanhChinh[0].status,
        dsMauHoSo: this.rowsThuTucHanhChinh[0].mauHoSos || [],
      }
      this.$axios.put(END_POINTS.THU_TUC_HANH_CHINH.SUA, this.$trimObject(payload), false).then(res => {
        if (checkStatus(res)) {
          this.tthcId = res.data.data
          this.getDataBieuMauThuTucHanhChinh()
          this.getChiTietThuTucHanhChinh(res.data.data)
          Promise.all([this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.DS_MAU_HO_SO_GT, { tthcId: res.data.data }), this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.CHI_TIET_GIAY_TO, { tthcId: res.data.data })]).then(ress => {
            this.thanhPhanHoSo.rows = ress[0].data.data
            this.thanhPhanHoSo.mhs = ress[1].data.data ? ress[1].data.data.map(item => ({
              code: item.id,
              id: item.id,
              label: item.tenMauHoSo,
              tenMauHoSo: item.tenMauHoSo,
            })) : []
            this.thanhPhanHoSo.tthcId = res.data.data
          })
          this.$refs.commonModal.hideModal()
        }
      })
    },
    addBieuMau() {
      this.title = 'Chọn biểu mẫu'
      this.componentName = 'DanhMucDanhSach'
      this.size = 'lg'
      this.dataForm = {
        rows: this.bmCbx.filter(item => !(this.rowsThuTucHanhChinh[0] && this.rowsThuTucHanhChinh[0].bieuMauId && this.rowsThuTucHanhChinh[0].bieuMauId.includes(item.id))) || [],
        columns: [
          {
            label: 'STT',
            thClass: 'text-center custom-style',
            tdClass: 'text-center',
            width: '50px',
            field: 'STT',
            sortable: false,
          },
          {
            label: 'Tên biểu mẫu',
            field: 'name',
            thClass: 'text-center',
          },
        ],
      }
      this.$refs.commonModal.showModal()
    },
    addNewCauHinhQuyTrinh() {
      this.isShow = true
      this.title = 'Thêm mới quy trình'
      this.componentName = 'LuongXuLyForm'
      this.size = 'lg'
      const result = this.rowsQuyTrinh.map(item => item.mauHoSoId)
      this.dataForm = {
        code: null,
        description: null,
        status: true,
        order: 0,
        activeVersion: 0,
        soNgayXuLy: 0,
        donViId: null,
        tthcId: this.tthcId,
        mauHoSoId: null,
        dsmauHoSo: this.thanhPhanHoSo?.mhs.filter(item => !result.includes(item.id)) || [],
      }
      this.beginObject = _cloneDeep(this.dataForm)
      this.$refs.commonModal.showModal()
    },
    deleteQuyTrinh(data) {
      this.isShow = false
      this.title = 'Xác nhận'
      this.componentName = 'XoaQuyTrinhForm'
      this.size = 'sm'
      this.dataForm = {
        ...data,
      }
      this.$refs.commonModal.showModal()
    },
    deletePhanQuyen(data) {
      this.isShow = false
      this.title = 'Xác nhận'
      this.componentName = 'XoaVaiTroForm'
      this.size = 'sm'
      this.dataForm = {
        ...data,
      }
      this.$refs.commonModal.showModal()
    },
    deleteBieuMau(data) {
      this.isShow = false
      this.title = 'Xác nhận xoá'
      this.componentName = 'XoaBieuMauForm'
      this.size = 'sm'
      this.dataForm = {
        ...data,
      }
      this.$refs.commonModal.showModal()
    },
    editorPaper(data) {
      this.isShow = true
      this.title = 'Cập nhật giấy tờ'
      this.componentName = 'GiayToMauHoSoFrom'
      this.size = 'xl'
      Promise.all([this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.DS_MAU_HO_SO_GT, { tthcId: data.id }), this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.CHI_TIET_GIAY_TO, { tthcId: data.id })]).then(res => {
        this.dataForm.rows = res[0].data.data
        this.dataForm.mhs = res[1].data.data ? res[1].data.data.map(item => ({
          id: item.id,
          code: item.id,
          label: item.tenMauHoSo,
          tenMauHoSo: item.tenMauHoSo,
        })) : []
        this.dataForm.tthcId = data.id
        this.$refs.commonModal.showModal()
      })
    },
    addCauHinhQuyTrinh() {
      this.isShow = true
      this.title = 'Thêm mới cấu hình quy trình'
      this.componentName = 'CauHinhQuyTrinhThuTucHanhChinh'
      this.size = 'xl'
      const result = this.rowsQuyTrinh.map(item => item.mauHoSoId)
      this.dataForm = {
        ds: [{
          workflowSchemeAttributeId: null,
          mauHoSoId: null,
          donViId: null,
          tongSoNgayXuLy: 0,
          mhsCbx: this.thanhPhanHoSo?.mhs.filter(item => !result.includes(item.id)) || [],
        }],
        mhsCbx: this.thanhPhanHoSo?.mhs.filter(item => !result.includes(item.id)) || [],
        thuTucHanhChinhId: this.tthcId,
      }
      this.beginObject = _cloneDeep(this.dataForm)
      this.$refs.commonModal.showModal()
    },
    updateQuyTrinh() {
      this.isShow = true
      this.title = 'Cập nhật cấu hình quy trình'
      this.componentName = 'CauHinhQuyTrinhThuTucHanhChinh'
      this.size = 'xl'
      const result = this.rowsQuyTrinh.map(item => item.mauHoSoId)
      this.dataForm = {
        id: this.tthcId,
        ds: this.rowsQuyTrinh.map(ele => ({
          id: ele.id,
          cauHinhQtId: ele.cauHinhQtId,
          workflowSchemeAttributeId: ele.workflowSchemeAttributeId,
          mauHoSoId: ele.mauHoSoId,
          donViId: ele.donViId,
          tongSoNgayXuLy: ele.tongSoNgayXuLy,
          mhsCbx: this.thanhPhanHoSo?.mhs.filter(item => !result.includes(item.id) || item.id === ele.mauHoSoId) || [],
        })),
        thuTucHanhChinhId: this.tthcId,
      }
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.dataForm)
        this.$refs.commonModal.showModal()
      })
    },
    async getDataNhomNguoiDung() {
      await this.$axios.get(END_POINTS.CHUNG.NHOM_NGUOI_DUNG, { tthcId: this.tthcId }).then(res => {
        if (res.data?.succeeded) {
          this.nhomNguoiDung = res.data.data
        }
      })
    },
    async getCauHinhQuyTrinh() {
      const payload = {
        pageNumber: 1,
        pageSize: 999999,
        value: null,
        name: null,
        type: 'BUSSINESS_FLOW',
        dataTTHCId: [this.tthcId],
        dataDonViId: [],
        dataMauHoSoId: [],
        schemeCode: null,
        fields: null,
        sort: 'Id',
        filter: null,
        fullTextSearch: null,
        id: null,
        listId: [],
      }
      await this.$axios.post(END_POINTS.WORKFLOW_GLOBAL_PARAMETERS.DS, payload, false).then(res => {
        if (res.data?.code === 200) {
          this.rowsQuyTrinh = res.data.data.content
        }
      })
    },
    selectedItemBieuMau(data) {
      this.dataForm.bieuMauId = data.map(item => item.id)
    },
    onSelectRow(data) {
      this.$isDisabelBtn = data.length
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    handleOkNotEmty() {
      this.isEmty = false
      this.$refs.commonModal.checkValidate()
    },
    handleOkEmty() {
      this.isEmty = true
      this.$refs.commonModal.checkValidate()
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        const payload = {
          listId: this.selectedItems.map(item => item.id),
        }
        this.$axios.delete(END_POINTS.THU_TUC_HANH_CHINH.XOA, payload).then(res => {
          if (res.data?.succeeded) {
            if (this.selectedItems.length >= this.rows.length && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataThuTucHanhChinh()
            this.$refs.commonModal.hideModal()
            if (res.data?.data) {
              res.data.data.forEach(item => {
                if (!item.succeeded) {
                  this.$toast.error(item.message)
                }
              })
            }
          }
        })
      } else if (this.componentName === 'XoaQuyTrinhForm') {
        const payload = {
          listId: [this.dataForm.id],
        }
        this.$axios.delete(END_POINTS.WORKFLOW_GLOBAL_PARAMETERS.XOA, payload, false).then(res => {
          if (res.data?.code === 200) {
            this.$toast.success(res.data.message)
            this.getCauHinhQuyTrinh()
            if (res.data.data) {
              res.data.data.forEach(item => {
                if (!item.succeeded) {
                  this.$toast.error(item.message)
                }
              })
            }
          } else {
            this.$toast.error(res.data.message)
          }
          this.$refs.commonModal.hideModal()
        })
      } else if (this.componentName === 'XoaVaiTroForm') {
        const payload = {
          data: [this.dataForm.id],
        }
        this.$axios.delete(END_POINTS.THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG.XOA, payload).then(res => {
          if (res.data?.succeeded) {
            this.getDataPhanQuyenThuTucHanhChinhNhomNguoiDung()
            this.$refs.commonModal.hideModal()
          }
          this.$refs.commonModal.hideModal()
        })
      } else if (this.componentName === 'ThuTucHanhChinhFrom') {
        this.dataForm.mauDonToKhai = this.dataForm.mauDonToKhai || []
        this.dataForm.phi = this.$refs.componentName.getDataPhi('phiInput') === 0 ? 0 : this.getNumber(this.$refs.componentName.getDataPhi('phiInput'))
        this.dataForm.lePhi = this.$refs.componentName.getDataPhi('lePhiInput') === 0 ? 0 : this.getNumber(this.$refs.componentName.getDataPhi('lePhiInput'))
        if (this.dataForm?.capDoChiTietTTHC !== 4) {
          this.dataForm.duongDanChuyenNganh = null
        }
        // eslint-disable-next-line prefer-const
        if (this.dataForm?.id) {
          this.dataForm.dsMauHoSo = this.dataForm.dsMauHoSo.map(item => ({
            id: item.id,
            tenMauHoSo: item.tenMauHoSo,
          })) || []
          this.$axios.put(END_POINTS.THU_TUC_HANH_CHINH.SUA, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.tthcId = res.data.data
              this.getChiTietThuTucHanhChinh(res.data.data)
              Promise.all([this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.DS_MAU_HO_SO_GT, { tthcId: res.data.data }), this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.CHI_TIET_GIAY_TO, { tthcId: res.data.data })]).then(ress => {
                this.thanhPhanHoSo.rows = ress[0].data.data
                this.thanhPhanHoSo.mhs = ress[1].data.data ? ress[1].data.data.map(item => ({
                  code: item.id,
                  id: item.id,
                  label: item.tenMauHoSo,
                  tenMauHoSo: item.tenMauHoSo,
                })) : []
                this.thanhPhanHoSo.tthcId = res.data.data
              })
              this.$refs.commonModal.hideModal()
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
              this.$refs.componentName.$refs.maThuTucHanhChinh.focus()
            }
          })
        } else {
          this.dataForm.dsMauHoSo = this.dataForm.dsMauHoSo.map(item => ({
            tenMauHoSo: item.tenMauHoSo,
          })) || []
          this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH.THEM, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.$refs.commonModal.hideModal()
              if (this.isEmty) {
                this.addCommom()
              }
              this.tthcId = res.data.data
              this.getChiTietThuTucHanhChinh(res.data.data)
              Promise.all([this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.DS_MAU_HO_SO_GT, { tthcId: res.data.data }), this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.CHI_TIET_GIAY_TO, { tthcId: res.data.data })]).then(ress => {
                this.thanhPhanHoSo.rows = ress[0].data.data
                this.thanhPhanHoSo.mhs = ress[1].data.data ? ress[1].data.data.map(item => ({
                  code: item.id,
                  id: item.id,
                  label: item.tenMauHoSo,
                  tenMauHoSo: item.tenMauHoSo,
                })) : []
                this.thanhPhanHoSo.tthcId = res.data.data
              })
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
              this.$refs.componentName.$refs.maThuTucHanhChinh.focus()
            }
          })
        }
      } else if (this.componentName === 'CauHinhQuyTrinhThuTucHanhChinh') {
        if (this.dataForm?.id) {
          try {
            const dataForm = this.dataForm.ds.map(item => {
              const wf = this.lxlCbx.find(ele => ele.id === item.workflowSchemeAttributeId)
              return {
                id: item.id,
                donViId: item.donViId,
                cauHinhQtId: item.cauHinhQtId,
                mauHoSoId: item.mauHoSoId,
                thuTucHanhChinhId: this.tthcId,
                tongSoNgayXuLy: item.tongSoNgayXuLy,
                type: 'BUSSINESS_FLOW',
                name: wf.description,
                value: JSON.stringify({
                  name: wf.description,
                  scheme: wf.code,
                }),
                workflowSchemeAttributeId: item.workflowSchemeAttributeId,
              }
            })
            this.$axios.put(END_POINTS.WORKFLOW_GLOBAL_PARAMETERS.SUA, dataForm, false).then(res => {
              if (res.data?.succeeded) {
                this.getCauHinhQuyTrinh()
                this.$refs.commonModal.hideModal()
                this.$toast.success(res.data.message)
                if (res.data.data) {
                  res.data.data.forEach(item => {
                    if (!item.succeeded) {
                      this.$toast.error(item.message)
                    }
                  })
                }
              } else {
                this.$toast.error(res.data.message)
              }
            })
          } catch (error) {
            this.$refs.componentName.errorTrungMa = 'Mã luồng xử lý tồn tại!'
          }
        } else {
          try {
            const dataForm = this.dataForm.ds.map(item => {
              const wf = this.lxlCbx.find(ele => ele.id === item.workflowSchemeAttributeId)
              return {
                type: 'BUSSINESS_FLOW',
                name: wf.description,
                value: JSON.stringify({
                  name: wf.description,
                  scheme: wf.code,
                }),
                cauHinhQtId: item.id,
                id: item.id,
                workflowSchemeAttributeId: item.workflowSchemeAttributeId,
                mauHoSoId: item.mauHoSoId,
                donViId: item.donViId,
                thuTucHanhChinhId: this.dataForm.thuTucHanhChinhId,
                tongSoNgayXuLy: +item.tongSoNgayXuLy,
              }
            })
            this.$axios.post(END_POINTS.WORKFLOW_GLOBAL_PARAMETERS.THEM, dataForm, false).then(res => {
              if (res.data?.succeeded) {
                this.getCauHinhQuyTrinh()
                this.$refs.commonModal.hideModal()
                this.$toast.success(res.data.message)
                if (res.data.data) {
                  res.data.data.forEach(item => {
                    if (!item.succeeded) {
                      this.$toast.error(item.message)
                    }
                  })
                }
              } else {
                this.$toast.error(res.data.message)
              }
            })
          } catch (error) {
            this.$refs.componentName.errorTrungMa = 'Mã luồng xử lý tồn tại!'
          }
        }
      } else if (this.componentName === 'ThuTucHanhChinhVaiTroForm') {
        const payload = this.dataForm.nhomNguoiDungId.map(item => ({
          thuTucHanhChinhId: this.tthcId,
          nhomNguoiDungId: item,
        }))
        this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG.THEM, payload).then(res => {
          if (res.data?.succeeded) {
            this.getDataPhanQuyenThuTucHanhChinhNhomNguoiDung()
            this.$refs.commonModal.hideModal()
          }
        })
      } else if (this.componentName === 'LuongXuLyForm') {
        if (this.dataForm?.id) {
          try {
            const dataForm = {
              code: this.dataForm.code,
              description: this.dataForm.description,
              status: this.dataForm.status,
              order: this.dataForm.order,
              activeVersion: this.dataForm.activeVersion,
              donViId: this.dataForm.donViId,
              tthcId: this.dataForm.tthcId,
              mauHoSoId: this.dataForm.mauHoSoId,
              soNgayXuLy: this.dataForm.soNgayXuLy,
              type: 'BUSSINESS_FLOW',
              name: this.dataForm.description,
              value: JSON.stringify({
                name: this.dataForm.description,
                scheme: this.dataForm.code,
              }),
            }
            this.$axios.put(`${END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.SUA}/${this.dataForm?.id}`, dataForm, false).then(res => {
              if (res.data?.code === 200) {
                this.getCauHinhQuyTrinh()
                this.$refs.commonModal.hideModal()
                this.$toast.success(res.data.message)
              } else if (res.data?.code === 400) {
                this.$refs.componentName.errorTrungMa = res.data.message
              } else {
                this.$toast.error(res.data.message)
              }
            })
          } catch (error) {
            this.$refs.componentName.errorTrungMa = 'Mã luồng xử lý tồn tại!'
          }
        } else {
          try {
            const dataForm = {
              code: this.dataForm.code,
              description: this.dataForm.description,
              status: this.dataForm.status,
              order: this.dataForm.order,
              activeVersion: this.dataForm.activeVersion,
              donViId: this.dataForm.donViId,
              tthcId: this.dataForm.tthcId,
              mauHoSoId: this.dataForm.mauHoSoId,
              soNgayXuLy: this.dataForm.soNgayXuLy,
              type: 'BUSSINESS_FLOW',
              name: this.dataForm.description,
              value: JSON.stringify({
                name: this.dataForm.description,
                scheme: this.dataForm.code,
              }),
            }
            this.$axios.post(END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.THEM, dataForm, false).then(res => {
              if (res.data?.code === 200) {
                this.getCauHinhQuyTrinh()
                this.$refs.commonModal.hideModal()
                this.$toast.success(res.data.message)
                const routeData = this.$router.resolve({ path: `/quan-ly-quy-trinh/quan-ly-luong-xu-ly/xem/${res.data.data.id}`, query: { tthcId: this.tthcId, donViId: res.data.data.donViId } })
                window.open(routeData.href, '_blank')
              } else if (res.data?.code === 400) {
                this.$refs.componentName.errorTrungMa = res.data.message
              } else {
                this.$toast.error(res.data.message)
              }
            })
          } catch (error) {
            this.$refs.componentName.errorTrungMa = 'Mã luồng xử lý tồn tại!'
          }
        }
      } else if (this.componentName === 'DanhMucDanhSach') {
        if (this.dataForm.bieuMauId && this.dataForm.bieuMauId.length > 0) {
          this.updateCopyBieuMau()
        } else {
          this.$toast.error('Hãy chọn 1 biểu mẫu!')
        }
      } else if (this.componentName === 'XoaBieuMauForm') {
        const payload = {
          id: this.rowsThuTucHanhChinh[0].id,
          maTTHC: this.rowsThuTucHanhChinh[0].maTTHC,
          soBoHoSo: this.rowsThuTucHanhChinh[0].soBoHoSo,
          tenTTHC: this.rowsThuTucHanhChinh[0].tenTTHC,
          linhVucId: this.rowsThuTucHanhChinh[0].linhVucId,
          coQuanThucHien: this.rowsThuTucHanhChinh[0].coQuanThucHien,
          phi: this.rowsThuTucHanhChinh[0].phi,
          lePhi: this.rowsThuTucHanhChinh[0].lePhi,
          kieuTTHC: this.rowsThuTucHanhChinh[0].kieuTTHC,
          loaiDichVuCong: this.rowsThuTucHanhChinh[0].loaiDichVuCong,
          loaiDichVuCong2: this.rowsThuTucHanhChinh[0].loaiDichVuCong2,
          capDoTTHC: this.rowsThuTucHanhChinh[0].capDoTTHC,
          capDoChiTietTTHC: this.rowsThuTucHanhChinh[0].capDoChiTietTTHC,
          cachThucThucHien: this.rowsThuTucHanhChinh[0].cachThucThucHien,
          tiepNhanQuaBuuDien: this.rowsThuTucHanhChinh[0].tiepNhanQuaBuuDien,
          traKetQuaQuaBuuDien: this.rowsThuTucHanhChinh[0].traKetQuaQuaBuuDien,
          trinhTuThucHien: this.rowsThuTucHanhChinh[0].trinhTuThucHien,
          thanhPhanHoSo: this.rowsThuTucHanhChinh[0].thanhPhanHoSo,
          mauDonToKhai: this.rowsThuTucHanhChinh[0].mauDonToKhais || [],
          yeuCauDieuKien: this.rowsThuTucHanhChinh[0].yeuCauDieuKien,
          thoiGianGiaiQuyet: this.rowsThuTucHanhChinh[0].thoiGianGiaiQuyet,
          doiTuongThucHien: this.rowsThuTucHanhChinh[0].doiTuongThucHien,
          ketQuaGiaiQuyet: this.rowsThuTucHanhChinh[0].ketQuaGiaiQuyet,
          canCuPhapLy: this.rowsThuTucHanhChinh[0].canCuPhapLy,
          trangThaiId: this.rowsThuTucHanhChinh[0].trangThaiId,
          bieuMauId: this.rowsThuTucHanhChinh[0].bieuMauId.filter(item => item !== this.dataForm.id),
          kieuHoSoId: this.rowsThuTucHanhChinh[0].kieuHoSoId,
          status: this.rowsThuTucHanhChinh[0].status,
          dsMauHoSo: this.rowsThuTucHanhChinh[0].mauHoSos || [],
        }
        this.$axios.put(END_POINTS.THU_TUC_HANH_CHINH.SUA, this.$trimObject(payload), false).then(res => {
          if (checkStatus(res)) {
            this.tthcId = res.data.data
            this.getDataBieuMauThuTucHanhChinh()
            this.getChiTietThuTucHanhChinh(res.data.data)
            Promise.all([this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.DS_MAU_HO_SO_GT, { tthcId: res.data.data }), this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.CHI_TIET_GIAY_TO, { tthcId: res.data.data })]).then(ress => {
              this.thanhPhanHoSo.rows = ress[0].data.data
              this.thanhPhanHoSo.mhs = ress[1].data.data ? ress[1].data.data.map(item => ({
                code: item.id,
                id: item.id,
                label: item.tenMauHoSo,
                tenMauHoSo: item.tenMauHoSo,
              })) : []
              this.thanhPhanHoSo.tthcId = res.data.data
            })
            this.$refs.commonModal.hideModal()
          }
        })
      } else if (this.componentName === 'BieuMauThuTucHanHChinhForm') {
        if (this.dataForm) {
          const payload = {
            id: this.dataForm,
            ...this.$refs.componentName.getData(),
            thuTucHanhChinhId: this.tthcId,
          }
          this.updateForm(payload)
        } else {
          const payload = {
            ...this.$refs.componentName.getData(),
            thuTucHanhChinhId: this.tthcId,
          }
          this.createForm(payload)
        }
      }
    },
    updateForm(payload) {
      this.$axios.put(END_POINTS.BIEU_MAU.capNhat(payload.id), this.$trimObject(payload)).then(res => {
        if (res.data.succeeded) {
          this.getDataBieuMauThuTucHanhChinh()
          this.getChiTietThuTucHanhChinh(this.tthcId)
          this.$refs.commonModal.hideModal()
        }
      })
    },
    createForm(payload) {
      this.$axios.post(END_POINTS.BIEU_MAU.URL(), this.$trimObject(payload)).then(res => {
        if (res.data.succeeded) {
          this.getDataBieuMauThuTucHanhChinh()
          this.getChiTietThuTucHanhChinh(this.tthcId)
          this.$refs.commonModal.hideModal()
        }
      })
    },
    getDataPhanQuyenThuTucHanhChinhNhomNguoiDung() {
      const payload = {
        nhomNguoiDungId: [],
        thuTucHanhChinhId: [{ id: this.tthcId }],
        pageNumber: 1,
        pageSize: 99999,
      }
      this.$axios.post(END_POINTS.THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG.DS, payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rowsPhanQuyen = res.data.data
        }
      })
    },
    getChiTietThuTucHanhChinh(id) {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.CHI_TIET, { id }).then(res => {
        if (res.data?.succeeded) {
          this.rowsThuTucHanhChinh = [{ ...res.data.data }]
        }
      })
    },
    getNumber(str) {
      const arr = str.split('')
      // eslint-disable-next-line prefer-const
      let out = []
      arr.forEach(item => {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(item) === false) {
          out.push(item)
        }
      })
      return Number(out.join(''))
    },
    updateCommom(data) {
      this.isShow = true
      this.title = 'Cập nhật thủ tục hành chính'
      this.componentName = 'ThuTucHanhChinhFrom'
      this.size = 'xl'
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.CHI_TIET, { id: data.id }).then(res => {
        if (res.data?.succeeded) {
          const result = res.data.data
          this.dataForm = {
            ...result,
            dsMauHoSo: result.mauHoSos.map(item => ({
              id: item.id,
              tenMauHoSo: item.tenMauHoSo,
              isDefault: item.tenMauHoSo === 'Bình thường',
              allowDelete: item.allowDelete,
            })) || [],
            phi: result.phi ? result.phi.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0,
            lePhi: result.lePhi ? result.lePhi.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0,
            mauDonToKhai: result.mauDonToKhais && result.mauDonToKhais.length > 0 ? result.mauDonToKhais : [],
          }
          this.$nextTick(() => {
            this.beginObject = _cloneDeep(this.dataForm)
            this.$refs.commonModal.showModal()
          })
        }
      })
    },
    handleFocusError(first) {
      if ((this.componentName === 'ThuTucHanhChinhFrom' && first) || (this.componentName === 'BieuMauThuTucHanHChinhForm' && first) || (this.componentName === 'ThuTucHanhChinhVaiTroForm' && first)
      || (this.componentName === 'LuongXuLyForm' && first) || (this.componentName === 'GiayToMauHoSoFrom' && first)) {
        const field = first[0]
        if (['linhVuc', 'coQuanBanHanh', 'nhomNguoiDungId', 'donVi', 'mauHoSo', 'giayTo'].includes(field)) {
          this.$refs.componentName.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs.componentName.$refs[field].focus()
        }
      }
    },
  },
}
</script>
