var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.data.disableCreate || !_vm.data.disableEdit)?_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Thủ tục","label-for":"input-1"}},[_c('validation-provider',{key:"thuTuc",attrs:{"rules":{ required: true },"name":"Thủ tục"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100 style-chooser",attrs:{"placeholder":"Chọn thủ tục hành chính","options":_vm.tthcCbx,"reduce":function (se) { return se.id; },"label":"label","filter":_vm.onFilterSelect,"disabled":true},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Không có dữ liệu ")]},proxy:true}],null,true),model:{value:(_vm.data.thuTucHanhChinhId),callback:function ($$v) {_vm.$set(_vm.data, "thuTucHanhChinhId", $$v)},expression:"data.thuTucHanhChinhId"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,549120915)})],1):_vm._e(),_c('b-form-group',{staticClass:"required",attrs:{"label":"Chi tiết cấu hình quy trình"}},[_c('vue-good-table',{attrs:{"columns":_vm.getColumns,"rows":_vm.data.ds},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'donViId')?_c('span',[_c('validation-provider',{key:props.index,attrs:{"rules":{ required: true },"name":("Đơn vị " + (props.index))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],attrs:{"options":_vm.dvCbx,"placeholder":"Chọn đơn vị","normalizer":_vm.normalizer,"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","match-keys":['label', 'name']},on:{"input":function($event){return _vm.changeDonVi(props.index)}},model:{value:(_vm.data.ds[props.index].donViId),callback:function ($$v) {_vm.$set(_vm.data.ds[props.index], "donViId", $$v)},expression:"data.ds[props.index].donViId"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):(props.column.field == 'mauHoSoId')?_c('span',[_c('validation-provider',{key:props.index,attrs:{"rules":{ required: true },"name":("Mã hồ sơ " + (props.index))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],attrs:{"options":_vm.getMauHoSo(_vm.data.ds[props.index].mhsCbx, _vm.data.ds[props.index].mauHoSoId),"placeholder":"Chọn mã hồ sơ","normalizer":_vm.normalizer,"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","match-keys":['label', 'name'],"disabled":_vm.data.thuTucHanhChinhId ? false : true},model:{value:(_vm.data.ds[props.index].mauHoSoId),callback:function ($$v) {_vm.$set(_vm.data.ds[props.index], "mauHoSoId", $$v)},expression:"data.ds[props.index].mauHoSoId"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):(props.column.field == 'workflowSchemeAttributeId')?_c('span',[_c('validation-provider',{key:props.index,attrs:{"rules":{ required: true },"name":("Quy trình " + (props.index))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],staticClass:"w-100 style-chooser",attrs:{"options":_vm.getDataQuyTrinhTheoDonVi(_vm.data.ds[props.index].donViId),"reduce":function (se) { return se.id; },"label":"description","placeholder":"Chọn quy trình","filter":_vm.onFilterSelect,"disabled":_vm.data.ds[props.index].donViId ? false : true},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Không có dữ liệu ")]},proxy:true}],null,true),model:{value:(_vm.data.ds[props.index].workflowSchemeAttributeId),callback:function ($$v) {_vm.$set(_vm.data.ds[props.index], "workflowSchemeAttributeId", $$v)},expression:"data.ds[props.index].workflowSchemeAttributeId"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):(props.column.field == 'tongSoNgayXuLy')?_c('span',[_c('validation-provider',{key:props.index,attrs:{"rules":{ required: true },"name":("tong So Ngay Xu Ly " + (props.index))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"block-e",rawName:"v-block-e"},{name:"format-positive-integers",rawName:"v-format-positive-integers"},{name:"is-int",rawName:"v-is-int"}],attrs:{"id":"input-1","type":"number","placeholder":"Nhập số ngày xử lý","required":""},model:{value:(_vm.data.ds[props.index].tongSoNgayXuLy),callback:function ($$v) {_vm.$set(_vm.data.ds[props.index], "tongSoNgayXuLy", $$v)},expression:"data.ds[props.index].tongSoNgayXuLy"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):(props.column.field == 'actions')?_c('span',[_c('div',{staticClass:"d-flex justify-content-center"},[(props.index + 1 == _vm.dataForm.ds.length)?_c('plus-square-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"custom-class cursor-pointer mr-1",attrs:{"title":"Thêm","size":"16"},on:{"click":function (e) { return _vm.dataForm.ds.push({workflowSchemeAttributeId: null, mauHoSoId: null, donViId: null, mhsCbx: _vm.dataForm.mhsCbx}); }}}):_vm._e(),(_vm.dataForm.ds.length > 1)?_c('trash-2-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"custom-class cursor-pointer",attrs:{"title":"Xoá","size":"16"},on:{"click":function($event){return _vm.deleteCauHinhQuyTrinh(props.index)}}}):_vm._e()],1)]):(props.column.field == 'STT')?_c('span',[_vm._v(" "+_vm._s(_vm.rowNumber(props))+" ")]):_vm._e()]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Không có dữ liệu ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }